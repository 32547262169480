import {FC, startTransition, useEffect} from 'react';

interface DataDogRUMProps {
  env: string;
  sampleRate?: number;
  enableScreenRecording?: boolean;
}

export const DataDogRUM: FC<DataDogRUMProps> = ({
  env,
  sampleRate = 50,
  enableScreenRecording = false,
}) => {
  useEffect(() => {
    if (env !== 'development') {
      startTransition(() => {
        (async () => {
          const {datadogRum} = await import('@datadog/browser-rum');

          datadogRum.init({
            applicationId: 'ba67563d-c0f4-4cc7-90b9-7d190d8fa414',
            clientToken: 'pub50937a00c43c838a0fe1301b7e6cb261',
            site: 'us3.datadoghq.com',
            service: 'wayward',
            env,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: sampleRate,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
          });

          if (enableScreenRecording) {
            datadogRum.startSessionReplayRecording();
          }
        })();
      });
    }
  }, []);

  return null;
};
