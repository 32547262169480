import {useLocation, useMatches} from '@remix-run/react';

import {FC} from 'react';

const missingKeys = [
  'og:type',
  'og:url',
  'og:image',
  'twitter:image',
  'twitter:card',
  'twitter:domain',
  'twitter:url',
];

function filterMissingKeys(seo: Record<string, string>) {
  return Object.keys(seo).filter((key) => missingKeys.includes(key));
}

export const SEOExtended: FC = () => {
  const matches = useMatches();
  const location = useLocation();
  const seoKeys = matches.reduce((res, match) => {
    const {handle, ...routeMatch} = match;
    const routeData = {...routeMatch, ...location};
    if (
      typeof match.handle?.seo === 'function' &&
      filterMissingKeys(match.handle.seo(routeData)).length > 0
    ) {
      res.push(match.handle.seo(routeData));
    }
    return res;
  }, []);
  const props = seoKeys?.[0];
  return props ? (
    <>
      {Object.keys(props)
        .filter((key) => missingKeys.includes(key))
        .map((key) => (
          <meta key={key} name={key} content={props[key]} />
        ))}
    </>
  ) : null;
};
