import {FC} from 'react';
import {hotjar} from 'react-hotjar';

interface HotjarProps {}

export const Hotjar: FC<HotjarProps> = () => {
  hotjar.initialize(3465752, 6);

  return null;
};
