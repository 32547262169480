import {
  AnalyticsEventName,
  ShopifyAddToCartPayload,
  ShopifyPageViewPayload,
  getClientBrowserParameters,
  sendShopifyAnalytics,
  useShopifyCookies,
} from '@shopify/hydrogen';
import {CartAction, I18nLocale} from '../../../lib/type';
import {useEffect, useRef} from 'react';
import {useFetchers, useLocation, useMatches} from '@remix-run/react';

import {useRudderStack} from './useRudderstack';
import {useSnowplow} from './useSnowplow';

export function useAnalytics(hasUserConsent: boolean, locale: I18nLocale) {
  useShopifyCookies({hasUserConsent, domain: 'wayward.com'});
  const snowplow = useSnowplow();
  const location = useLocation();
  const lastLocationKey = useRef<string>('');
  const {stack, trackEvent} = useRudderStack();
  const analyticsFromMatches = useDataFromMatches(
    'analytics',
  ) as unknown as ShopifyPageViewPayload;

  // Page view analytics
  // We want useEffect to execute only when location changes
  // which represents a page view
  useEffect(() => {
    if (lastLocationKey.current === location.key) return;

    lastLocationKey.current = location.key;
    const pageAnalytics = {
      ...analyticsFromMatches,
      currency: locale.currency,
      acceptedLanguage: locale.language,
      hasUserConsent,
    };

    const payload: ShopifyPageViewPayload = {
      ...getClientBrowserParameters(),
      ...pageAnalytics,
    };

    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
    });

    if (typeof snowplow === 'function') {
      snowplow('trackPageView', {
        title: document.title,
        context: [
          {
            schema:
              'iglu:com.articaanalytics.shopify.webpixel/context/jsonschema/1-0-0',
            data: {
              window: {
                innerHeight: window.innerHeight,
                innerWidth: window.innerWidth,
                outerHeight: window.outerHeight,
                outerWidth: window.outerWidth,
                pageXOffset: window.pageXOffset,
                pageYOffset: window.pageYOffset,
                screenX: window.screenX,
                screenY: window.screenY,
                scrollX: window.scrollX,
                scrollY: window.scrollY,
                origin: window.origin,
                location: window.location,
              },
              document: {
                title: document.title,
                referrer: document.referrer,
                location: document.location,
                characterSet: document.characterSet,
              },
              navigator: {
                cookieEnabled: navigator.cookieEnabled,
                language: navigator.language,
                languages: navigator.languages,
                userAgent: navigator.userAgent,
              },
            },
          },
        ],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, snowplow]);

  useEffect(() => {
    if (typeof stack?.page === 'function') {
      const payload = {
        ...getClientBrowserParameters(),
        ...analyticsFromMatches,
        currency: locale.currency,
        acceptedLanguage: locale.language,
        hasUserConsent,
      };
      stack.page('prt', payload.path, payload);
    }
  }, [
    analyticsFromMatches,
    hasUserConsent,
    locale.currency,
    locale.language,
    stack,
  ]);

  const cartData = useDataFromFetchers({
    formDataKey: 'cartAction',
    formDataValue: CartAction.ADD_TO_CART,
    dataKey: 'analytics',
  }) as unknown as ShopifyAddToCartPayload;

  useEffect(() => {
    if (cartData) {
      const pageAnalytics = {
        ...analyticsFromMatches,
        currency: locale.currency,
        acceptedLanguage: locale.language,
        hasUserConsent,
      };

      const addToCartPayload: ShopifyAddToCartPayload = {
        ...getClientBrowserParameters(),
        ...pageAnalytics,
        ...cartData,
        currency: 'USD',
      };

      sendShopifyAnalytics({
        eventName: AnalyticsEventName.ADD_TO_CART,
        payload: addToCartPayload,
      });

      if (
        typeof snowplow === 'function' &&
        typeof cartData.products !== 'undefined' &&
        (cartData?.products?.length || 0) > 0
      ) {
        snowplow('trackAddToCart', {
          sku: cartData.products[0].variantGid,
          name: cartData.products[0].name,
          unitPrice: parseFloat(cartData.products[0].price),
          quantity: cartData.products[0].quantity,
          currency: 'USD',
          context: [
            {
              schema:
                'iglu:com.articaanalytics.shopify.webpixel/product_added_to_cart/jsonschema/1-0-0',
              data: {
                cartLine: {
                  cost: {
                    totalAmount: {
                      amount: cartData.totalValue,
                      currencyCode: 'USD',
                    },
                  },
                  merchandise: {
                    id: cartData.products[0].variantGid,
                    sku: cartData.products[0].sku || '',
                    image: {
                      src: '',
                    },
                    price: {
                      amount: parseFloat(cartData.products[0].price),
                      currencyCode: 'USD',
                    },
                    product: {
                      id: cartData.products[0].productGid,
                      title: cartData.products[0].name,
                      vendor: cartData.products[0].brand,
                    },
                    title: cartData.products[0].variantName,
                  },
                  quantity: cartData.products[0].quantity,
                },
              },
            },
          ],
        });
      }
    }
  }, [
    analyticsFromMatches,
    cartData,
    hasUserConsent,
    locale.currency,
    locale.language,
    snowplow,
  ]);
}

/**
 * Collects data under a certain key from useMatches
 * @param dataKey - The key in `event.data` to collect data from
 * @returns A merged object of the specified key
 *
 * @example
 * ```tsx
 * import {
 *   useDataFromMatches
 * } from '@shopify/hydrogen';
 *
 * export async function loader({request, context}: LoaderArgs) {
 *   return defer({
 *     analytics: {
 *       shopId: 'gid://shopify/Shop/1',
 *     },
 *   });
 * }
 *
 * export default function App() {
 *   const analytics = useDataFromMatches('analytics');
 *

 * ```
 **/
function useDataFromMatches(dataKey: string): Record<string, unknown> {
  const matches = useMatches();
  const data: Record<string, unknown> = {};

  matches.forEach((event) => {
    const eventData = event?.data;
    if (eventData && eventData[dataKey]) {
      Object.assign(data, eventData[dataKey]);
    }
  });

  return data;
}

/**
 * Collects data under a certain key from useFetches.
 *
 * @param formDataKey - The form data key
 * @param formDataValue - The value of formDataKey
 * @param dataKey - the key in `fetcher.data` to collect data from
 * @returns A merged object of the specified key
 *
 * @example
 * ```tsx
 * // In routes/cart.tsx
 * import {
 *   useDataFromFetchers
 * } from '@shopify/hydrogen';
 *
 * export async function action({request, context}: ActionArgs) {
 *   const cartId = await session.get('cartId');
 *   ...
 *   return json({
 *     analytics: {
 *       cartId,
 *     },
 *   });
 * }
 *
 * // Anywhere when an action can be requested, make sure there is a form input and value
 * // to identify the fetcher
 * export function AddToCartButton({
 *   ...
 *   return (
 *     <fetcher.Form action="/cart" method="post">
 *       <input type="hidden" name="cartAction" value={CartAction.ADD_TO_CART} />
 *
 * // You can add additional data as hidden form inputs and it will also be collected
 * // As long as it is JSON parse-able.
 * export function AddToCartButton({
 *
 *   const analytics = {
 *     products: [product]
 *   };
 *
 *   return (
 *     <fetcher.Form action="/cart" method="post">
 *       <input type="hidden" name="cartAction" value={CartAction.ADD_TO_CART} />
 *       <input type="hidden" name="analytics" value={JSON.stringify(analytics)} />
 *
 * // In root.tsx
 * export default function App() {
 *   const cartData = useDataFromFetchers({
 *     formDataKey: 'cartAction',
 *     formDataValue: CartAction.ADD_TO_CART,
 *     dataKey: 'analytics',
 *   });
 *
 *   // {
 *   //   cartId: 'gid://shopify/Cart/abc123',
 *   //   products: [...]
 *   // }
 * ```
 **/
function useDataFromFetchers({
  formDataKey,
  formDataValue,
  dataKey,
}: {
  formDataKey: string;
  formDataValue: unknown;
  dataKey: string;
}): Record<string, unknown> | undefined {
  const fetchers = useFetchers();
  const data: Record<string, unknown> = {};

  for (const fetcher of fetchers) {
    const formData = fetcher.submission?.formData;
    const fetcherData = fetcher.data;
    if (
      formData &&
      formData.get(formDataKey) === formDataValue &&
      fetcherData &&
      fetcherData[dataKey]
    ) {
      Object.assign(data, fetcherData[dataKey]);

      try {
        if (formData.get(dataKey)) {
          const dataInForm: unknown = JSON.parse(String(formData.get(dataKey)));
          Object.assign(data, dataInForm);
        }
      } catch {
        // do nothing
      }
    }
  }
  return Object.keys(data).length ? data : undefined;
}
